<template>
  <button class="ms-login-button flex items-center shadow hover:shadow-md">
    <img class="ms-login-button-icon" src="@/assets/images/logos/ms-symbollockup_mssymbol_19.svg" alt="Microsoft Logo" />
    <span class="ms-login-button-text">{{ t('login') }}</span>
  </button>
</template>

<i18n lang="json">
{
  "en": {
    "login": "Sign in with Microsoft"
  },
  "fr": {
    "login": "Se connecter avec Microsoft"
  }
}
</i18n>

<script setup>
const { t } = useI18n({
  useScope: 'local',
})
</script>

<script>
export default {
  name: 'MicrosoftSignInButton',
}
</script>

<style lang="postcss" scoped>
.ms-login-button {
  @apply bg-white;

  height: 41px;

  /* border-color: #8c8c8c; */
  padding-left: 12px;
  padding-right: 12px;
}

.ms-login-button-icon {
  margin-right: 12px;
}

.ms-login-button-text {
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.54);
}
</style>
